import _isEmpty from 'lodash/isEmpty'

const phone = {
  type: 'input',
  name: 'phone',
  label: 'Tài khoản',
  placeholder: 'Nhập số điện thoại',
  require: true,
  props: {
    disabled: true
  }
}

const requester = {
  type: 'input',
  name: 'requester',
  label: 'Thuộc về',
  placeholder: 'Họ và tên',
  props: {
    disabled: true
  }
}

export default { phone, requester }
